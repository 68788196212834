.App {
  text-align: center;
}

.vertical-timeline-element-content {
  box-shadow: none;
  background-color: #5C6370;
  color: #fff;
}
.vertical-timeline-element-content-arrow {
  border-right-color: #5C6370;
}
